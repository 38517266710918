import { useState, useEffect, useContext } from 'react';
import { Language } from '../context/language'

import './other.css'

const Terms = ({ page }) => {

  const { language } = useContext(Language);



const english = (
  <>
  <h1>VoteFlare™ Privacy Statement</h1>
    <p>
      Welcome to VoteFlare™, a voter registration monitoring service offered by the Public Interest Tech Lab at Harvard University (“Harvard”) on the Harvard Public Interest Tech Lab's MyDataCan platform.
    </p>

    <p>
      By accessing content on the VoteFlare™.org website (the “Site,” which includes all pages within the VoteFlare™.org web domain), subscribing to or using the VoteFlare™ app (the “App”) on MyDataCan, and/or
      accessing the VoteFlare™ Service, you accept and agree to be bound by the following Terms of Service:
    </p>
    <ol>
      <li>
        <p>
          <u>
            Scope and Description of Service
          </u>.
          The VoteFlare™ Service (the “Service”) is a voter registration monitoring service offered by the Public Interest Tech Lab at Harvard University. The Service includes all
          services provided to you by VoteFlare™, including the Site and the App. We explain below how the Service works, what it does and does not do, and what its limitations are.
        </p>
        <p>
          Here is how the Service works:
        </p>
        <ul>
          <li>
            <u>
              You set up a MyDataCan User Account
            </u>
            . In order to use the Service, you will need to establish a user account (“User Account”) with MyDataCan. Then you can subscribe to the App.
          </li>
          <li>
            <p>
              <u>
                You provide your Home State and Required Information
              </u>
              . When you subscribe to the App, VoteFlare™ gives you access to the VoteFlare™ website (the “Site”). The Site will ask you to identify the state (including
              the District of Columbia) where you live and where you believe you should be registered to vote. The state that
              you identify is your “Home State.” Depending on the Home State you have identified, VoteFlare™ will prompt you to input certain Required Information. The “Required Information” is any
              information that VoteFlare™ needs in order to access your Home State’s Voter Registration Database on your behalf or compare to a Registered Voter List issued by your Home State.
            </p>

            <p>
              Most, but not all, states or counties have established online webpages that residents can access in order to verify
              the current status of their voter registration and/or access additional information about voting (such as the location of their polling places or vote-by-mail options). When we talk about “Voter
              Registration Databases” in these Terms of Service, we are referring to these web-based resources.
            </p>
            <p>
              Several states’ voter registration authorities also make available full lists of registered voters. When we talk about “Registered Voter Lists,” we are referring to these lists.&nbsp;
            </p>
          </li>

          <li>
            <u>
              We offer the service free of charge
            </u>
            . VoteFlare™ is a non-profit project and we welcome donations.</li>

          <li>
            <p>

              <u>
                VoteFlare™ monitors your voter registration status
              </u>
              . VoteFlare™ monitors your voter registration by periodically checking your Home State’s Voter Registration Database and/or its Registered Voter List.
            </p>
            <p>
              If your Home State is one for which VoteFlare™ checks the state’s Voter Registration Database, VoteFlare™ will periodically visit the webpage of that database, enter your Required Information, and record
              into MyDataCan the registration information reported back by the database, as of that date and time.
            </p>
            <p>
              If your Home State is one for which VoteFlare™ checks the state’s Registered Voter List, VoteFlare™ will periodically obtain the state’s most recent Registered Voter List
              and compare your Required Information against that latest list.
            </p>
          </li>
          <li>
            <u>
              VoteFlare™ keeps you updated you about your voter registration status
            </u>. VoteFlare™ will send you an email, a text message, or a recorded phone message (according to your preference) to tell you about the following developments:
            <ul>
              <li>
                VoteFlare™ has checked your Home State’s Voter Registration Database or Registered Voter List <u>for the first time</u> 
                &nbsp;and has found that
                &nbsp;<u>you are not registered to vote
                </u> in your Home State.
              </li>
              <li>
                VoteFlare™ has checked your Home State’s Voter Registration Database or Registered Voter List and has found
                that your voter registration status&nbsp;
                <u>
                  has changed
                </u>.
              </li>
            </ul>
            <p>
              Other than the notifications described above, we will&nbsp;
              <u>
                not disclose any details about your registration status or voter information&nbsp;
              </u>
              in any of these communications. You will be required to log in to the Site to see the details.&nbsp;
              <u>
                We will not ask you for any personal information in any of these communications
              </u>, either.
            </p>
            <p> In addition, you may log in to the MyDataCan platform at any time and see the most recent information captured by
              VoteFlare™ when it last checked your Voter Registration Database or Registered Voter List, along with historical
              information from prior VoteFlare™ captures from your Voter Registration Database or Registered Voter List. You may
              also access the latest information on the VoteFlare™ website, which receives a feed of the information stored in MyDataCan.&nbsp;
            </p>
          </li>

          <li>
            <u>
              Upon request VoteFlare™ connects you with someone who can assist you with your voter registration
            </u>.
            Depending on your Home State, VoteFlare™ may have partner organizations (“Registration Partners”)
            that can help you register to vote. The App includes a setting that allows you to choose whether you would like
            to have a representative of one of our Registration Partners contact you, either upon your request or when your voter registration has changed.
          </li>


        </ul>
        <p><strong>
        <u>
          Some Important Caveats:
        </u>
        </strong></p>
        <ul>
          <li>
            <u>
              VoteFlare™ *does not* register you to vote.
            </u>&nbsp;
            VoteFlare™ only checks to see whether you are registered in the
            Home State you listed and, if so, monitors whether you continue to be registered.
            </li>

          <li>
            <u>
              VoteFlare™ *does not* change or correct your voter information
            </u>
            . VoteFlare™ only tells you about your
            registration status.
          </li>

          <li>
            <u>
              The VoteFlare™ Service is available only through Election Day: November 8, 2022
            </u>
            . VoteFlare™ may make this voter registration monitoring service available for subsequent elections. We will try to keep you posted.
          </li>
          <li>
            <u>
              VoteFlare™ relies on the information recorded in the Voter Registration Databases and Registered Voter Lists
            </u>
            . VoteFlare™’s purpose is to report back to you the information given to us by your Home State at that date and time. If the information contained in a
            Voter Registration Database or Registered Voter List is incorrect or not updated, VoteFlare™ will not know it. VoteFlare™ does not verify or guarantee that
            the information provided by your Home State in these Databases or Lists is accurate.
          </li>

          <li>
            <u>
              VoteFlare™ only checks your voter registration status in the Home State you listed
            </u>
            . VoteFlare™ does not check to see if you are registered in any other state. If you entered the wrong Home State, VoteFlare™ will not
            know. If you maintain residences in more than one state and you are not sure if you are registered in both or
            all states, you will need to choose just one Home State at a time for VoteFlare™ to monitor. It is
            not possible for VoteFlare™ to monitor more than one Home State from a single User Account.
          </li>
        </ul>
      </li>
    </ol>
    <ol start="2">
      <li>
        <p>
          <u>
            Appropriate Use/ Conduct
          </u>
          . You agree that you are responsible for your own use of the Service. You agree
          that you will use the Service in compliance with these Terms of Service and all applicable local, state,
          national and international laws, rules and regulations, including privacy and copyright laws, any laws
          regarding the transmission of technical data exported from your country of residence, and all United States export control laws.
        </p>

        <p>
          In addition, you agree that you will&nbsp;
          <u>
            not
          </u>
          :</p>
        <ul>
          <li>
            Impersonate any other person, including by establishing a User Account under another person’s name or using another person’s personal information;
          </li>
          <li>
            Access the Service for any reason other than to access your own voter registration status or other voting information;
          </li>
          <li>
            Reverse-engineer the Service;
          </li>
          <li>
            Use the Service in any manner intended to damage, disable, overburden, interfere with or disrupt any part of the
            Service, the MyDataCan platform (“the Platform”) or the computer equipment or network(s) connected
            to either the Platform or the Service, or any other User’s access to or use of the Platform or theService;
          </li>
          <li>
            Attempt to gain unauthorized access to the Platform or the Service, any Platform or Service user accounts, or
            computer equipment or networks connected to the Platform or Service via hacking, password mining, or any other means;
          </li>
          <li>
            Obtain or attempt to obtain any materials or information on or via the Platform or the Service not intentionally made available to you through the Platform or the Service;
          </li>
          <li>
            Copy or use any portion of the Service;
          </li>
          <li>
            Use any high volume, automated, or electronic means to access the Service (including without limitation robots, spiders or scripts);
          </li>
          <li>
            Frame the Site, place pop-up windows over its pages, or otherwise affect the display of its pages; or
          </li>
          <li>
            Force headers or otherwise manipulate identifiers in order to disguise the origin of any communication transmitted through the Service.
          </li>
        </ul>
      </li>
    </ol>
    <ol start="3">
      <li>
        <u>
          Consent to Collection and Use of Information
        </u>
        . The Service may collect data for each User. You acknowledge that you have read and understand the MyDataCan Privacy Statement and VoteFlare™ Privacy Addendum.&nbsp;
      </li>
    </ol>
    <ol start="4">
      <li>
        <u>
          DISCLAIMER OF WARRANTY
        </u>
        . TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, THE SERVICE AND ANY INFORMATION, PRODUCTS, OR SERVICES THEREIN OR OBTAINED THEREBY ARE PROVIDED “AS IS” WITHOUT
        WARRANTY OF ANY KIND (EXPRESS, IMPLIED, OR OTHERWISE), INCLUDING, WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR
        NONINFRINGEMENT. WE DO NOT WARRANT THAT THE SERVICE WILL OPERATE IN AN UNINTERRUPTED OR ERROR-FREE MANNER OR THAT THE SERVICE IS FREE OF VIRUSES OR OTHER HARMFUL
        COMPONENTS. WITHOUT LIMITING THE FOREGOING, WE DO NOT WARRANT THAT (A) THE SERVICE WILL MEET YOUR REQUIREMENTS OR EXPECTATIONS OR ACHIEVE THE INTENDED PURPOSES;
        (B) THE SERVICE WILL NOT EXPERIENCE OUTAGES OR OTHERWISE WILL BE UNINTERRUPTED, TIMELY, OR SECURE; (C) THE INFORMATION OR SERVICES OBTAINED THROUGH OR FROM THE SERVICE
        WILL BE ACCURATE, COMPLETE, CURRENT, ERROR-FREE, OR RELIABLE; OR (D) ANY DEFECTS IN OR ON THE SERVICE WILL BE CORRECTED. WE MAKE NO REPRESENTATION OR WARRANTY REGARDING
        YOUR ABILITY TO TRANSMIT AND RECEIVE INFORMATION FROM OR THROUGH THE SERVICE, AND YOU AGREE AND ACKNOWLEDGE THAT YOUR ABILITY TO ACCESS THE SERVICE MAY BE IMPAIRED.
      </li>
    </ol>
    <ol start="5">
      <li>
        <p>
          <u>
            LIMITATION OF LIABILITY
          </u>
          . EXCEPT INSOFAR AS THE FOLLOWING LIMITATION MAY BE PROHIBITED BY APPLICABLE LAW, WE SHALL NOT BE LIABLE TO YOU OR TO ANY THIRD PARTY FOR ANY CONSEQUENTIAL,
          INDIRECT, PUNITIVE, SPECIAL, OR INCIDENTAL DAMAGES, WHETHER FORESEEABLE OR UNFORESEEABLE (INCLUDING, BUT NOT LIMITED TO, LOSS OF PROFITS OR EARNING POWER,
          LOSS OF DATA, LOSSES DUE TO ERRORS OR INTERRUPTION IN AVAILABILITY OF THE SERVICE, UNAVAILABILITY OF ANY SERVICE, SERVER, OR COMMUNICATIONS FACILITY, OR DAMAGES
          DUE TO ACTS OR OMISSIONS OF OTHERS USING THE SERVICE), ARISING OUT OF OR RELATING TO THE SERVICE, INCLUDING WITHOUT LIMITATION YOUR AND OTHERS’ USE OF
          OR INABILITY TO USE THE SERVICE, OR YOUR RELIANCE UPON INFORMATION OBTAINED FROM OR THROUGH THE SERVICE, WHETHER BASED IN CONTRACT, TORT, STATUTORY, OR OTHER LAW.
          OUR TOTAL CUMULATIVE LIABILITY TO YOU ARISING OUT OF OR RELATED TO THE SERVICE (INCLUDING, WITHOUT LIMITATION, IN THE WAYS DESCRIBED IN THE PRECEDING SENTENCE),
          WHETHER BASED IN CONTRACT, TORT, STATUTORY OR OTHER LAW, WILL NOT EXCEED THE AMOUNT, IF ANY, THAT YOU PAID US TO USE THE SERVICE IN THE TWELVE MONTHS PRECEDING THE CLAIM.
          THE DISCLAIMERS AND LIMITATIONS SET FORTH IN THIS SECTION SHALL APPLY, TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, WHETHER OR NOT WE HAVE BEEN NEGLIGENT OR OTHERWISE AT FAULT.
        </p>
        <p>
          YOU ACKNOWLEDGE AND AGREE THAT THE WARRANTY DISCLAIMERS AND THE LIMITATIONS OF LIABILITY SET FORTH IN THESE TERMS OF SERVICE REFLECT A REASONABLE AND FAIR
          ALLOCATION OF RISK BETWEEN YOU AND US, AND THAT THESE LIMITATIONS ARE ESSENTIAL TO OUR WILLINGNESS TO MAKE THE SERVICE AVAILABLE TO YOU. EACH OF THESE DISCLAIMERS AND
          LIMITATIONS IS INTENDED TO BE SEPARATELY ENFORCEABLE, REGARDLESS OF WHETHER ANY OTHER REMEDY FAILS OF ITS ESSENTIAL PURPOSE.
        </p>
        <p>
          YOU ACKNOWLEDGE THAT, FOR PURPOSES OF THE FOREGOING DISCLAIMERS AND LIMITATIONS, AS WELL AS THE INDEMNITY AND THIRD-PARTY PROVISIONS IN SECTIONS 6 AND 7 BELOW, THE TERMS
          “WE,” “OUR,” “US” AND “VoteFlare™” INCLUDE THE CORPORATE BODY PRESIDENT AND FELLOWS OF HARVARD COLLEGE, ALSO KNOWN AS HARVARD UNIVERSITY, AND ITS VARIOUS SCHOOLS, THE MEMBERS
          OF ITS GOVERNING BOARDS, AND ITS OFFICERS, FACULTY MEMBERS, EMPLOYEES, AND FELLOWS, AND TO THE EXTENT THEY ARE WORKING ON THE VoteFlare™ PROJECT, ITS STUDENTS,
          CONTRACTORS AND REPRESENTATIVES (TOGETHER, THE “COVERED PERSONS”).
        </p>
        <p>
          CERTAIN JURISDICTIONS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF THE LAWS OR REGULATIONS OF THESE
          JURISDICTIONS APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS, EXCLUSIONS, OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MIGHT HAVE ADDITIONAL RIGHTS.
        </p>
      </li>
    </ol>
    <ol start="6">
      <li>
        <u>
          Indemnification
        </u>
        . You agree to indemnify and hold harmless us (as defined for this purpose in Section 5 above) from any and all claims, liabilities, damages,
        losses and expenses, including reasonable attorneys’ fees and costs, relating to or arising out of (a) your use or attempted use of the Service in violation of
        these Terms of Service; or (b) your violation of any law or rights of any third party.
      </li>
    </ol>
    <ol start="7">
      <li>
        <u>
          Links and Third-Party Apps and Services
        </u>
        . The Service may include hyperlinks or referrals to websites or applications maintained or controlled by others, including apps developed and deployed by third parties for
        use on the Platform, services and support offered by Registration Partners, and rideshare programs and services. We are not responsible for and do not approve or endorse
        the contents or use of any of the information, products, or services that may be offered by these third-party websites or applications. If you decide to access linked
        third-party websites or applications, you do so at your own risk. You expressly relieve VoteFlare™ from any liability arising from your use of any third-party site.
        You expressly relieve Harvard and the Covered Harvard Persons from any liability arising from your use of any third-party website or application.
      </li>
    </ol>
    <ol start="8">
      <li>
        <u>
          Choice of Law
        </u>
        . You agree that the Terms of Service and any claim or dispute arising out of or relating to the Terms of Service or your use of the Service will be governed
        by the laws of the Commonwealth of Massachusetts, excluding its conflicts of laws principles. You agree that all such claims and disputes will be heard and resolved
        exclusively in the federal or state courts located in and serving Middlesex or Suffolk County, Massachusetts, U.S.A. You consent to the personal jurisdiction of
        those courts over you for this purpose, and you waive and agree not to assert any objection to such proceedings in those courts (including any
        defense or objection of lack of proper jurisdiction or inconvenience of forum).
      </li>
    </ol>
    <ol start="9">
      <li>
        <u>
          Whole Agreement/ Amendments
        </u>
        . These Terms of Service and the Privacy Policy together constitute the entire agreement between you and Harvard with respect to your use of the Platform
        and this Service. Harvard reserves the right to amend these Terms of Service at any time. The Service will post notice of changes to the terms
        on this webpage, and by accessing the Service after modifications to these Terms of Service have been posted, you agree to be bound by all the modified
        terms. Accordingly, you should periodically revisit this page to review the then-current Terms of Service.
      </li>
    </ol>
    <p>Revised January 4, 2021</p>
    </>
)


const spanish = (
  <>
    <h1>
      LOS TÉRMINOS DE SERVICIO PARA VoteFlare™ EN LA PLATAFORMA MYDATACAN
    </h1>

    <p>
      Bienvenidos a VoteFlare™, un servicio de monitoreo de registro de electores ofrecido por el Laboratorio de Privacidad de Datos (Privacy Lab) de la Universidad de Harvard ("Harvard") en la plataforma MyDataCan del Laboratorio de Privacidad de Datos de Harvard.
    </p>

    <p>
      Al hacer clic en el botón YO ACEPTO a continuación, usted certifica lo siguiente:
    </p>
    <ol>
      <li>
        <p>
        Yo acepto y estoy de acuerdo con estar sujeto a los Términos de Servicio y el Anexo de Privacidad de VoteFlare.

        La información que he proporcionado al Servicio es cierta, precisa, completa y actualizada.

        Soy quien digo ser, y la información que he proporcionado al Servicio es información sobre mi persona y solamente sobre mi persona.

        Designo a Harvard, a actuar a través de VoteFlare, como mi agente para acceder al Registro Electoral de mi estado de residencia, a la base de datos o lista de electores registrados en mi nombre, con el único propósito de monitorear el registro electoral de mi estado, y (según esté disponible) mi lugar de votación o la opción de votar ausente por correo y que seré informado.

        Entiendo que VoteFlare accederá a mi información de registro y votación y me reportará, y que VoteFlare no cambiará ninguna de la información.

        Como parte de la suscripción al Servicio de VoteFlare, he revelado a Harvard cierta información sobre mi persona. Yo autorizo a Harvard a utilizar la información que sea necesaria para monitorear el estatus de mi registro electoral, utilizando la base de datos del registro de mi estado de origen y/o la Lista de Electores Registrados.


        Al acceder al contenido del sitio web voteflare.org (el "Sitio " el cual incluye todas las páginas dentro del dominio web voteflare.org), subscribirse al o utilizar la aplicación VoteFlare(el "App") a través de MyDataCan, y/o al accesar el Servicio de VoteFlare, usted acepta y está de acuerdo con estar sujeto a los siguientes Términos de Servicio:

        El alcance y la descripción del Servicio. El Servicio de VoteFlare (el "Servicio") es un servicio de monitoreo de registro electoral ofrecido por el Laboratorio de Privacidad de la Universidad de Harvard. El Servicio incluye todos los servicios proporcionados por VoteFlare, incluyendo la página web y el app.

        A continuación, le explicamos cómo funciona el Servicio qué es lo que hace y no hace, y cuáles son las limitaciones.
        </p>
        <p>
        Así es como funciona el Servicio:
        </p>
        <ul>
          <li>
          Usted configura una cuenta de usuario MyDataCan. Para poder utilizar el Servicio, usted tendrá que crear una cuenta de usuario ("Cuenta de Usuario") a través de MyDataCan. Luego se puede suscribir al App.
          </li>
          <li>
            <p>
              Usted proporciona la información sobre el estado donde reside y la demás información requerida. Cuando usted se suscribe al App, VoteFlare™ le da acceso a la página web de VoteFlare™ (el "Sitio"). El sitio web le pedirá que identifique el estado (o el Distrito de Columbia)donde usted reside y donde cree que debería estar inscrito para votar.
            </p>
            <p>
              El estado que usted identifique será su "Estado de Residencia" dependiendo del estado que usted haya identificado, VoteFlare™ le pedirá que ingrese cierta información requerida. Dicha "Información Requerida" es cualquier información que VoteFlare™ necesite para poder accesar la base de datos del Registro Electoral de su estado, en su nombre y necesite comparar la Lista de Electores Registrados emitida por su estado de residencia.
            </p>
            <p>
              La mayoría, pero no todos los estados o condados han establecido una página web en línea a la cual los residentes pueden tener acceso. Para verificar el estado actual de su registro electoral y/o acceder a información adicional sobre la elección (tal como la ubicación de los lugares donde se puede votar y las opciones disponibles para votar ausente).
            </p>
            <p>
              Cuando nos referimos a la "Base de Datos de Lista de Electores Registrados" en estos Términos de Servicio, nos referimos a estos recursos que se encuentran en la web.
            </p>
            <p>
              Las autoridades de registro de electores de varios estados también hacen disponible listas completas de sus electores registrados. Cuando nos referimos a "Listas de Electores Registrados", nos referimos a estas listas.
            </p>
          </li>


          <li>
            <p>

              Usted paga por el Servicio. VoteFlare™ es un proyecto sin fines de lucro. Sin embargo, VoteFlare™ necesita cobrar por el Servicio para poder cubrir el costo.
            </p>
            <p>
              Usted puede adquirir el Servicio con su tarjeta de crédito o con un cupón/código, o puede tener acceso a los servicios de forma gratuita durante los períodos donde haya alguna promoción.
            </p>
            <p>
              VoteFlare™ monitorea el estatus de su registro de elector. VoteFlare™ monitorea el registro electoral al verificar periódicamente la base de datos de registro electoral del estado donde usted reside y/o el registro de la lista de electores.
            </p>
            <p>
              Si el estado donde usted reside es uno en el cual VoteFlare™ verifica la base de datos del registro electoral del estado, VoteFlare™ verificará periódicamente la página web de esta base de datos, entrará la información que se le requirió a usted y grabará en MyDataCan la información de registro que reporte la base de datos en tal fecha y hora.
            </p>
            <p>
              Si el estado donde usted reside es uno en el cual VoteFlare™ verifica la Lista de Electores Registrados, VoteFlare™ obtendrá periódicamente la información más reciente de la Lista de Electores Registrados del estado y comparará con su Información Requerida con la lista más reciente.
            </p>
          </li>
          <li>
            VoteFlare™ lo mantiene informado sobre el estatus de su registro electoral. VoteFlare™ le enviará un email, un mensaje de texto, o un mensaje telefónico grabado (según sea su preferencia) para dejarle saber de los siguientes acontecimientos:
            <ul>
              <li>
              Que VoteFlare™ ha verificado la base de datos del registro de electores del estado donde usted reside por primera vez y ha encontrado que usted no está registrado para votar en el estado donde reside.
              </li>
              <li>
              Que VoteFlare™ ha verificado la base de datos del estado donde usted reside y la lista de electores registrados y ha encontrado que ha habido algún cambio en el estatus de su registro de elector.
              </li>
            </ul>

            <p>
              Además de la notificación descrita anteriormente, no revelaremos ningún otro detalle del estatus de su inscripción ni de su información de elector en ninguna de estas comunicaciones que se le envíen. Usted tendrá que iniciar una sesión en el sitio web para ver los detalles. Nosotros no le pediremos ninguna información personal en ninguna de estas comunicaciones.
            </p>
            <p>
            Además, usted puede accesar la plataforma MyDataCan y verificar la información más reciente que haya sido adquirida por VoteFlare™ la última vez que verificó la base de datos del registro de electores o la lista de electores registrados de su estado, así como el historial de la información anteriormente recopilada por VoteFlare™ a través de la base de datos del registro electoral o la lista de electores registrados de su estado. Usted también puede accesar esta información en el sitio
            web de VoteFlare™, el cual recibe la información almacenada en MyDataCan.
            </p>
          </li>

          <li>
            <u>
              Si usted lo solicita, VoteFlare™ lo conecta con alguien que le pueda asistir para registrarse como elector.
            </u>.
            Dependiendo del estado donde usted resida, VoteFlare™ puede que tenga organizaciones asociadas o ("Socios de Registro") en ese estado que le pueden ayudar a registrarse para votar. El App incluye una configuración que le permite escoger si usted quisiera que alguno de nuestros asociados que trabajan con el registro se comuniquen con usted, sea cuando usted lo solicite o cuando haya habido algún cambio en su registro electoral.

          </li>

        </ul>

        <ul>
          <li>
              VoteFlare™ *no * lo registra para poder votar. VoteFlare™ solamente verifica si usted está registrado en el estado donde usted reside y si está registrado, confirma que siga estando registrado.
          </li>
          <li>
            VoteFlare™ *no* cambia ni corrige su información de elector. VoteFlare™ solamente le deja saber el estatus de su registro.
          </li>
          <li>
              El servicio de VoteFlare™ está disponible solamente hasta el día de las elecciones: 8 de noviembre de 2022. VoteFlare™ puede hacer que este servicio de monitoreo del registro electoral esté disponible para otras elecciones subsiguientes.
          </li>
          <li>
              Trataremos de mantenerlo informado.
              VoteFlare™ depende de la información que se ingrese en la base de datos del registro electoral y en la lista de los electores registrados.
              El propósito de VoteFlare™ es compartir con usted la información que nos ha provisto el estado donde usted reside en una determinada fecha y hora. Si la información contenida en la base de datos de los electores o la lista de electores registrados es incorrecta o no ha sido puesta al día, VoteFlare™ no tiene manera de saberlo. VoteFlare™ no verifica ni garantiza que la información provista por el estado donde usted reside en esta base de datos o en estas listas sea precisa.
          </li>

          <li>
          VoteFlare™ solamente verifica su estatus en el registro electoral del estado que usted indicó como el estado donde usted reside. VoteFlare™ no verifica si usted se ha registrado en algún otro estado. Si usted no ingresó el estado donde usted reside correctamente, VoteFlare™ no tiene manera de saberlo. Si usted mantiene residencia en más de un estado y no está seguro si usted se ha registrado en ambos estados, usted tendrá que escoger solamente un estado al momento de solicitar el   servicio de monitoreo de VoteFlare™.
            VoteFlare™ no puede monitorear más de un estado de residencia para una cuenta de usuario.
          </li>
        </ul>
      </li>
    </ol>
    <ol start="2">
      <li>
        <p>
          <u>
            El uso y la conducta apropiada.
          </u>
          Usted acepta que es responsable del uso de este Servicio. Usted está de acuerdo con que utilizará dicho Servicio siguiendo los Términos de Servicio y todas las leyes, reglas y reglamentos tanto locales como estatales, federales e internacionales, incluyendo las leyes de privacidad y derecho de autor, cualquier ley relacionada a la transmisión de datos tecnológicos exportados desde el país donde usted reside y todas las leyes relacionadas al control de la exportación de los Estados Unidos de América.
        </p>

        <p>
          Además, usted acepta que no llevará a cabo lo siguiente:
        </p>
        <ul>
          <li>
            No se hará pasar por otra persona, incluyendo el crear una Cuenta de Usuario utilizando el nombre o la información personal de otra persona;
          </li>
          <li>
            No accesará el Servicio para ninguna otra razón que no sea accesar su estatus de registro electoral u otra información relacionada al registro de elector;
          </li>
          <li>
            Ingeniería inversa del Servicio;
          </li>
          <li>
            No utilizará el Servicio de ninguna manera que busque dañar, deshabilitar, sobrecargar, interferir o interrumpir cualquier parte del Servicio, la plataforma MyDataCan ("la Plataforma") o el equipo de computadoras o la red a la cual esté conectada a la Plataforma o al Servicio, o a cualquier acceso del Usuario para utilizar la Plataforma o el Servicio;
          </li>
          <li>
            No intentará obtener acceso no autorizado a la Plataforma o al Servicio, a cualquier cuenta de la Plataforma o el Servicio, ni las cuentas de usuarios de la Plataforma o el Servicio, ni los equipos o redes conectadas a la Plataforma o al Servicio a través de hacking, ni la minería de contraseñas, o cualquier otro método;

          </li>
          <li>
            No obtendrá ni intentará obtener ningún material o información en o a través de la Plataforma o el Servicio que no esté intencionalmente disponible para usted a través de la Plataforma o Servicio;
          </li>
          <li>
            No copiará ni utilizará ninguna porción del Servicio;
          </li>
          <li>
            No utilizará ningún medio de gran volumen, automatizado o electrónico para accesar al Servicio (esto incluye entre otras cosas robots, arañas o guiones);
          </li>
          <li>
            No enmarcará el Sitio, no colocará ventanas emergentes sobre sus páginas ni de alguna otra manera afectará cómo se ve la página;
          </li>
          <li>
            No forzará los encabezados o de alguna otra manera intentará manipular los identificadores con el propósito de disfrazar el origen de cualquier comunicación transmitida a través del Servicio.
          </li>
        </ul>
      </li>
    </ol>
    <ol start="3">
      <li>
          Consentir a la recopilación y el uso de información. El Servicio puede recolectar data de cada Usuario.
          Usted reconoce que ha leído y entendido la Declaración de Privacidad de
          MyDataCan y el Adendum de Privacidad de VoteFlare™.
      </li>
    </ol>
    <ol start="4">
      <li>
        <u>
          LIMITACIÓN DE RESPONSABILIDAD.
        </u>
        EN LA MÁS ALTA MEDIDA PERMITIDA POR LA LEY APLICABLE, EL SERVICIO Y CUALQUIER INFORMACIÓN, PRODUCTOS O SERVICIOS OBTENIDOS A TRAVÉS DE ESTOS SE PROPORCIONAN “TAL CUAL” SIN GARANTÍA DE NINGÚN TIPO EXPRESA, IMPLÍCITA O DE ALGÚN OTRO MODO), ESTO INCLUYE, SIN LIMITACIÓN, CUALQUIER GARANTÍA IMPLÍCITA DE COMERCIABILIDAD, APTITUD PARA UN PROPÓSITO EN PARTICULAR O NO INFRACCIÓN. NO SE GARANTIZA QUE EL SERVICIO OPERARÁ DE MANERA ININTERRUMPUDA O SIN ERRORES NI QUE EL SERVICIO ESTARÁ LIBRE DE VIRUSES O DE CUALQUIER OTRO COMPONENTE QUE PUEDA CAUSAR DAñOS SIN LIMITAR LO ANTERIORMENTE EXPUESTO, NO SE GARANTIZA QUE (A)EL SERVICIO CUMPLIRÁ CON SUS REQUISITOS O EXPECTATIVAS O ALCANCE LOS FINES PREVISTOS; (B) EL SERVICIO NO EXPERIMENTARÁ APAGONES O QUE DE OTRA MANERA SERÁ ININTERRUMPIDO, PUNTUAL O SEGURO; (C) LA INFORMACIÓN O LOS SERVICIOS OBTENIDOS A TRAVÉS O DEL SERVICIO SERÁN PRECISOS, COMPLETOS, ACTUALIZADOS, SIN ERRORES O CONFIABLES;O (D) CUALQUIER DEFECTO EN O SOBRE EL SERVICIO SERÁ CORREGIDO. NO HACEMOS REPRESENTACIÓN ALGUNA NI GARANTÍA RELACIONADA A SU ABILIDAD DE TRANSMITIR Y RECIBIR INFORMACIÓN DE O A TRAVÉS DEL SERVICIO,Y USTED ACEPTA Y RECONOCE QUE SU CAPACIDAD DE ACCESAR EL SERVICIO PUEDE SER OBSTRUIDA.
      </li>
    </ol>
    <ol start="5">
      <li>
        <p>
          <u>
            LIMITACIÓN DE LA RESPONSABILIDAD.
          </u>
          EXCEPTO EN LA MEDIDA EN LA QUE LA SIGUIENTE LIMITACIÓN PUEDA SER PROHIBIDA POR LA LEY APLICABLE, NO SEREMOS RESPONSABLES ANTE USTED NI ANTE NINGUN TERCERO POR NINGÚNA CONSECUENCIA INDIRECTA, PUNITIVA, ESPECIAL NI POR NINGÚN DAñO INCIDENTAL, SEA PREVISIBLE O IMPREVISIBLE INCLUYENDO PERO SIN LIMITARSE A LA PÉRDIDA GANANCIAS O LUCRO CESANTE, PÉRDIDA DE DATOS, PÉRDIDAS DEBIDAS A ERRORES O INTERRUPCIONES DEBIDAS A UNA INTERRUPCIÓN DE LOS SERVICIOS, LA NO DISPONIBILIDAD DE CUALQUIERA DE LOS SERVICIOS, SERVIDOR O FACILIDADES DE COMUNICACIÓN, NI DAñOS CAUSADOS POR ACTOS U OMISIONES AL UTILIZAR EL SERVICIO), LOS CUALES SURJAN O ESTÉN RELACIONADOS AL SERVICIO, INCLUYENDO SIN LIMITACIÓN SU UTILIZACIÓN DEL SERVICIO O LA DE UN TERCERO, O LA INCAPACIDAD DE UTILIZAR EL SERVICIO, O DE SU CONFIANZA EN LA INFORMACIÓN OBTENIDA POR EL SERVICIO, SEA ESTA BASADA EN UN CONTRATO, DAñOS Y PERJUICIOS, EN UN ESTATUTO O EN CUALQUIER OTRA LEY.
        </p>
        <p>
        NUESTA RESPONSABILIDAD TOTAL ACUMULADA ANTE USTED QUE SURJA O ESTÉ RELACIONADA CON EL SERVICIO (INCLUYENDO, PERO SIN LIMITACIÓN, EN LAS MANERAS DESCRITAS EN LAS PÁRRAFOS ANTERIORES), ESTÉN ESTAS BASADAS EN UN CONTRATO, DAñOS Y PERJUICIOS, EN UN ESTATUTO O EN ALGUNA OTRA LEY
        , NO EXCEDERÁN LA CANTIDAD, SI ALGUNA, QUE USTED NOS HAYA PAGADO PARA PODER UTILIZAR EL SERVICIO DURANTE LOS DOCE MESES ANTES DEL RECLAMO.

        </p>
        <p>
          LAS RENUNCIAS Y LIMITACIONES DE RESPONSABILIDAD ESTABLECIDAS EN ESTA SECCIÓN SERÁN APLICADAS, EN LA MEDIDA MÁS AMPLIA PERMITIDA POR LA LEY APLICABLE, HAYA MEDIADO O NO CULPA O NEGLIGENCIA POR NUESTRA PARTE.
        </p>
        <p>
          USTED RECONOCE Y ACEPTA QUE LA RENUNICA DE GARANTÍA Y LAS LIMITACIONES DE RESPONSABLIDAD ESTABLECIDA MEDIANTE ESTOS TÉRMINOS DE SERVICIO REFLEJAN UNA ASIGNACIÓN RAZONABLE Y JUSTA DEL RIESGO EXISTENTE ENTRE USTED Y NOSOTROS, Y QUE DICHAS LIMITACIONES SON ESENCIALES PARA QUE ESTEMOS DISPUESTOS A HACER QUE NUESTRO SERVICIO ESTÉ DISPONIBLE PARA USTED.
        </p>
        <p>
        CADA UNA DE ESTAS EXENCIONES Y LIMITACIONES DE RESPONSABILIDAD HAN DE SER APLICABLES INDIVIDUALMENTE, SIN IMPORTAR SI FALLA ALGÚN OTRO REMEDIO DENTRO DE SU PROPÓSITO ESENCIAL.
        </p>
        <p>
        USTED RECONOCE QUE, PARA LOS PROPÓSITOS DE LAS SIGUIENTES RENUNCIAS Y LIMITACIONES DE RESPONSABILIDAD, ASÍ COMO PARA LA INDEMNIZACIÓN Y LAS DISPOSICIONES DE TERCEROS EN LAS SECCIONES 6 Y 7 A CONTINUACIÓN LOS TÉRMINOS “NOSOTROS” Y “NUESTROS”, Y “VoteFlare™” INCLUYEN AL PRESIDENTE DEL ORANISMO CORPORATIVO Y A LOS ASOCIADOS AL HARVARD COLLEGE TAMBIÉN CONOCIDO COMO LA UNIVERSIDAD DE HARVARD, Y SUS VARIAS FACULTADES, LOS MIEMBROS DE LA JUNTA DE DIRECTORES Y SUS FUNCIONARIOS, LOS MIEMBROS DE LA FACULTAD, LOS EMPLEADOS Y LOS COLEGAS O COMPAñEROS, Y EN LA MEDIDA EN QUE TRABAJEN CON EL PROYECTO VoteFlare™, SUS ESTUDIANTES, CONTRATISTAS Y REPRESENTANTES (EN CONJUNTO "LOS INDIVIDUOS CUBIERTOS ").
        </p>
        <p>
          CIERTAS JURISDICCIONES NO PERMITEN LIMITACIONES A LAS GARANTÍAS IMPLÍCITAS NI LA EXCLUSIÓN O LIMITACIÓN DE CIERTOS DAñOS Y PERJUICIOS. SI LAS LEYES O REGLAMENTOS DE ESTAS JURISDICCIONES LE APLICAN A USTED, ALGUNAS DE LAS LIMITACIONES O EXLCUSIONES MENCIONADAS PUEDE QUE NO LE APLIQUEN A USTED, POR LO QUE USTED PODRÍA TENER ALGUNOS DERECHOS ADICIONALES.
        </p>
      </li>
    </ol>
    <ol start="6">
      <li>
        La indemnización. Usted acepta indemnizarnos y eximirnos de responsabilidad (según se define para este propósito en la Sección 5) de todas y cada una de las reclamaciones, responsabilidades, daños, pérdidas y gastos incluyendo los gastos y honorarios de abogados, relacionados con o que surjan de (a) su utilización o intento de utilización de nuestros Servicios en violación a estos Términos de Servicio
        ; o (b) en caso de que usted viole cualquier ley o cualquier derecho de un tercero.
      </li>
    </ol>
    <ol start="7">
      <li>
        Los enlaces y las aplicaciones y a servicios de terceros. El Servicio puede incluir hipervínculos o referidos a sitios web o a aplicaciones mantenidas o controladas por terceros, incluyendo aplicaciones desarrolladas y mantenidas por terceros para la utilización de la Plataforma, los servicios y el apoyo ofrecido por los Socios de Registro, y los servicios y el apoyo de los programas y servicios de transportación.
        Nosotros no somos responsables de y no aprobamos ni promovemos el contenido ni el uso de la información, de los productos, ni de los servicios que puedan ofrecer estos sitios web o estas aplicaciones de terceros. Si usted decide accesar un enlace de una página web o una aplicación de un tercero, lo hace bajo su propio riesgo.
        Usted expresamente releva a VoteFlare™ de cualquier responsabilidad que surja del uso de cualquier sitio de un tercero. Usted libera expresamente a Harvard y a cualquier Persona Cubierta de Harvard de cualquier responsabilidad que surja del uso de cualquier sitio web o aplicación de un tercero.
      </li>
    </ol>
    <ol start="8">
      <li>
        La elección de la ley. Usted acepta que los Términos de Servicio y cualquier reclamo o disputa que surja de o que esté relacionada con los Términos de Servicio o del uso del Servicio se regirán por las leyes del estado de Massachusetts, excluyendo los conflictos en cuanto a los principios legales. Usted acepta que todas las reclamaciones y disputas serán atendidas y resueltas exclusivamente en los tribunales federales o estatales que están localizadas y que sirven a los condados de Middlesex o Suffolk, Massachusetts, EE. UU. Usted acepta la jurisdicción de dichos tribunales sobre su persona para estos propósitos y usted renuncia y acepta que no levantará ninguna objeción a tales procedimientos en dichos tribunales (incluyendo cualquier defensa u objeción por falta de jurisdicción o por inconveniencia del foro).
      </li>
    </ol>
    <ol start="9">
      <li>
        Acuerdo completo / Enmiendas. Estos Términos de Servicio y esta Política de Privacidad en conjunto constituyen el acuerdo completo entre usted y Harvard en relación con su utilización de la Plataforma y de este Servicio.
        Harvard se reserva el derecho de enmendar estos Términos de Servicio en cualquier momento. El Servicio publicará un aviso de los cambios a los términos en su página web, y al accesar el Servicio después de haberse llevado a cabo la modificación a estos Términos de Servicio, usted acepta estar sujeto a todos los términos que hayan sido modificados. Por lo tanto, usted debe visitar periódicamente esta página para revisar los Términos de Servicio vigentes en ese momento.
      </li>
    </ol>
    <p>Revisado el 4 de enero de 2021</p>
    </>
)


  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className='other'>
     {
       language.lang == 'en' ? english : spanish
     }
    </div>
  );
}

export default Terms;
