import React, { useContext, useEffect, useState } from "react";
import { FormProvider, useForm, useFormContext } from "react-hook-form";

import { Language } from '../context/language'

const AlertPrefs = () => {
    const { register, watch } = useFormContext();
    const watchPartnerAlertsEnabled = watch("partnerAlertsEnabled", false);

    const { language } = useContext(Language);

    return (
        <div className='alertpref'>
            <label>Preferred Language</label>
            <label htmlFor="field-english">
                <input
                    {...register("contactLanguage")}
                    type="radio"
                    name="contactLanguage"
                    value="English"
                    id="field-english"
                />
                {language.english}
            </label>
            <label htmlFor="field-spanish">
                <input
                    {...register("contactLanguage")}
                    type="radio"
                    name="contactLanguage"
                    value="Spanish"
                    id="field-spanish"
                />
                {language.spanish}
            </label>

            <label>{language.alertPrefs.email}</label>
            <input {...register("contactEmail")} />

            <label>{language.alertPrefs.phoneSms}</label>
            <input {...register("contactSms")} />

            <label>{language.alertPrefs.phoneVoice}</label>
            <input {...register("contactVoice")} />

            <table class="alertsource" style={{marginBottom:'20px'}}>
                <thead>
                    <tr>
                        <th>{language.alertPrefs.sourceOfAlerts}</th>
                        <th>{language.alertPrefs.email}</th>
                        <th>{language.alertPrefs.sms}</th>
                        <th>{language.alertPrefs.voice}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{language.alertPrefs.alertsAboutVoteflare}</td>
                        <td>
                            <input type="checkbox" value="email" {...register('alertPrefsMain')} />
                        </td>
                        <td>
                            <input type="checkbox" value="sms"  {...register('alertPrefsMain')} />
                        </td>
                        <td>
                            <input type="checkbox" value="voice"  {...register('alertPrefsMain')} />
                        </td>
                    </tr>
                    <tr>
                        <td colspan="4">
                            <input type="checkbox"  {...register('partnerAlertsEnabled')}/>
                            &nbsp;
                            {language.alertPrefs.enablePartnerAlerts}
                        </td>
                    </tr>
                    {
                        watchPartnerAlertsEnabled &&
                        <tr>
                            <td>
                                {language.alertPrefs.alertsAboutPartners}
                                <a href="/terms/">*</a>
                            </td>
                            <td>
                                <input type="checkbox" value="email"  {...register('alertPrefsPartner')} />
                            </td>
                            <td>
                                <input type="checkbox" value="sms" {...register('alertPrefsPartner')} />
                            </td>
                            <td>
                                <input type="checkbox" value="voice" {...register('alertPrefsPartner')} />
                            </td>
                        </tr>
                    }
                </tbody>
            </table>
        </div>
    )
};

export default AlertPrefs;
