import { useState, useEffect, useContext } from 'react';
// import axios from '../../tools/axiosWrapper'
import ReactPlayer from 'react-player'

import { Link } from "react-router-dom";

import { ReactComponent as BoothSVG } from '../media/booth.svg'
import { Language } from '../context/language'

import TextAnimation from './textanimation/textanimation'

import Video from '../media/vf_2.mov'
import VideoSpanish from '../media/vf_s.mp4'
import axios from '../tools/axiosWrapper';


import './home.css'

const Home = ({ }) => {

  function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
      });
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return windowSize.width > 700 ? 0 : 1;
}

  const mobile = useWindowSize();

  const { language, set_language } = useContext(Language);

  const onClickSignupButton = async () => {
    try {
      const { data } = await axios.get('/auth/login');
      let url = data.login_url;
      url = url.replace("/login", "/signup");

      window.location = url;
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <main>
      <h1>
        {language.home1}
      </h1>
      <div style={{width: '100vw', display: 'flex', justifyContent: 'space-around', alignItems:'center' }}>
      <BoothSVG className='booth' />
      <button
        onClick={onClickSignupButton}
        className='signup'>
        { language.signup }
      </button>
      </div>


      <h2>
        {language.home2}
      </h2>
      <TextAnimation />

      <ReactPlayer width={mobile ? '95vw' : '640px'} height={mobile ? '54vw' : '360px'} style={{marginBottom: '200px'}} url={language.lang == 'en' ? Video : VideoSpanish} controls={true} />

      <div className='secure'>
        <h2 style={{textTransform:'uppercase'}}>
          {language.home3}
        </h2>
        <h3>
          {language.home4}
          <br/>
          <br/>
          {language.home5}
          <br/>
          <br/>
          {language.home6}
        </h3>
      </div>



    </main>
  );
}

export default Home;
