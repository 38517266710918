import React from "react";
import { useFormContext } from "react-hook-form";

const StateSelect = () => {
    const { register } = useFormContext(); 

    return (
        <select {...register("state", { required: true })}>
            {/* <option value="GA">Georgia</option> */}
            <option value="TX">Texas</option>
            {/* <option value="AL" disabled>Alabama</option>
            <option value="AK" disabled>Alaska</option>
            <option value="AZ" disabled>Arizona</option>
            <option value="AR" disabled>Arkansas</option>
            <option value="CA" disabled>California</option>
            <option value="CO" disabled>Colorado</option>
            <option value="CT" disabled>Connecticut</option>
            <option value="DE" disabled>Delaware</option>
            <option value="DC" disabled>District Of Columbia</option>
            <option value="FL" disabled>Florida</option>
            <option value="GA" disabled>Georgia</option>
            <option value="HI" disabled>Hawaii</option>
            <option value="ID" disabled>Idaho</option>
            <option value="IL" disabled>Illinois</option>
            <option value="IN" disabled>Indiana</option>
            <option value="IA" disabled>Iowa</option>
            <option value="KS" disabled>Kansas</option>
            <option value="KY" disabled>Kentucky</option>
            <option value="LA" disabled>Louisiana</option>
            <option value="ME" disabled>Maine</option>
            <option value="MD" disabled>Maryland</option>
            <option value="MA" disabled>Massachusetts</option>
            <option value="MI" disabled>Michigan</option>
            <option value="MN" disabled>Minnesota</option>
            <option value="MS" disabled>Mississippi</option>
            <option value="MO" disabled>Missouri</option>
            <option value="MT" disabled>Montana</option>
            <option value="NE" disabled>Nebraska</option>
            <option value="NV" disabled>Nevada</option>
            <option value="NH" disabled>New Hampshire</option>
            <option value="NJ" disabled>New Jersey</option>
            <option value="NM" disabled>New Mexico</option>
            <option value="NY" disabled>New York</option>
            <option value="NC" disabled>North Carolina</option>
            <option value="ND" disabled>North Dakota</option>
            <option value="OH" disabled>Ohio</option>
            <option value="OK" disabled>Oklahoma</option>
            <option value="OR" disabled>Oregon</option>
            <option value="PA" disabled>Pennsylvania</option>
            <option value="RI" disabled>Rhode Island</option>
            <option value="SC" disabled>South Carolina</option>
            <option value="SD" disabled>South Dakota</option>
            <option value="TN" disabled>Tennessee</option>
            <option value="UT" disabled>Utah</option>
            <option value="VT" disabled>Vermont</option>
            <option value="VA" disabled>Virginia</option>
            <option value="WA" disabled>Washington</option>
            <option value="WV" disabled>West Virginia</option>
            <option value="WI" disabled>Wisconsin</option>
            <option value="WY" disabled>Wyoming</option> */}
        </select>
    );
}

export default StateSelect;
