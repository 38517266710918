import React, { useContext, useEffect, useState, useMemo } from "react"
import { FormProvider, useForm, useFormContext } from "react-hook-form"
import { Navigate } from 'react-router-dom'
import countyList from "./counties"
import StateSelect from "./stateSelect"
import DateSelect from "./dateSelect";
import AlertPrefs from "./alertPrefs"
import axios from '../tools/axiosWrapper'
import { Language } from '../context/language'

import {ReactComponent as ProfileSVG} from '../media/icons/profile.svg'
import {ReactComponent as AlertSVG} from '../media/icons/alert.svg'

import './completesignup.css'



const CountySelect = ({ counties }) => {
	const { register } = useFormContext()
	const optionElements = []

	for (const county of counties) {
		let value = county.toUpperCase();
		optionElements.push(<option key={value} value={value}>{county}</option>)
	}

	return (
		<select {...register("county", { required: true })}>
			{optionElements}
		</select>
	)
}


const CompleteSignUp = () => {
	const { language } = useContext(Language);

	const [ isLoading, setIsLoading ] = useState(false)
	const [ isSubmitting, setIsSubmitting ] = useState(false)

	const methods = useForm()

	const { register, handleSubmit, watch, setValue, formState: { errors } } = methods
	const watchState = watch('state', 'TX')

	const [ counties, setCounties ] = useState([])
	const [ isSignedUp, setIsSignedUp ] = useState(false)

	const onSubmit = (data) => {
		setIsSubmitting(true)

		axios.post('/settings', data)
		.then(res => {
			setIsSignedUp(true)
		})
		.finally(() => {
			setIsSubmitting(true)
			window.scrollTo(0, 0)
		})

	}

	useEffect(() => {
		let currentState = watchState
		let currentCounties = countyList[currentState]
		setCounties(currentCounties)
	}, [ watchState, setValue ])

	useEffect(() => {
		setIsLoading(true)
		axios.get('/voter/info')
		.then(res => {
			const { data } = res
			setValue('email', data.email)
			setValue('firstname', data.firstname)
			setValue('lastname', data.lastname)

			let mm = data.birthdate.substring(0, 2);
			setValue('birthMonth', mm);
			
			let dd = data.birthdate.substring(2, 4);
			setValue('birthDay', dd);
			
			let yyyy = data.birthdate.substring(4, 8);
			setValue('birthYear', yyyy);

			setValue('county', data.county)
			setValue('zip', data.zip)

			setValue('contactLanguage', data.input.contact_language)
			setValue('contactEmail', data.input.contact_email)
			setValue('contactSms', data.input.contact_sms)
			setValue('contactVoice', data.input.contact_voice)
			setValue('alertPrefsMain', data.input.main)
			setValue('partnerAlertsEnabled', data.input.partner.length > 0)
			setValue('alertPrefsPartner', data.input.partner)
		})
		.finally(() => {
			setIsLoading(false)
		})

	}, [])

	if (isSignedUp) {
		return <Navigate to='/portal' />
	}

	if (isLoading) {
		return <p>{language.completesignup.loading}</p>
	}

	return (
		<FormProvider {...methods}>
			<form className='mainform' onSubmit={handleSubmit(onSubmit)}>
				<div className='mainform-head'>
					<ProfileSVG/>
					<div>{language.completesignup.info}</div>
				</div>

				<label>{language.completesignup.username}</label>
				<input defaultValue="test" {...register("email")} readOnly />

				<label>{language.completesignup.firstname}</label>
				<input {...register("firstname", { required: true })}/>
				{errors.firstname && <span>{language.completesignup.required}</span>}

				<label>{language.completesignup.lastname}</label>
				<input {...register("lastname", { required: true })} />
				{errors.lastname && <span>{language.completesignup.required}</span>}

				<label>{language.completesignup.state}</label>
				<StateSelect  />
				{errors.state && <span>{language.completesignup.required}</span>}

				<label style={{marginTop:'20px'}}>{language.completesignup.county}</label>
				<CountySelect counties={counties} />
				{errors.county && <span>{language.completesignup.required}</span>}

				<label style={{marginTop:'20px'}}>{language.completesignup.zip}</label>
				<input {...register("zip", { required: true })} />
				{errors.zip && <span>{language.completesignup.required}</span>}

				<label style={{marginTop:'20px'}}>{language.completesignup.birthdate}</label>
				<DateSelect />

				<div className='mainform-head' style={{marginTop:'50px'}}>
					<AlertSVG/>
					<div>{language.completesignup.alertSettings}</div>
				</div>
				<AlertPrefs />


				<button type="submit" disabled={isSubmitting}>
					{isSubmitting ? language.completesignup.submitting : language.completesignup.submit}
				</button>
			</form>

		</FormProvider>
	)
}

export default CompleteSignUp
