import React, { useContext } from "react";
import { useFormContext } from "react-hook-form";

import { Language } from '../context/language'

import './dateSelect.css';

const MonthSelect = () => {
    const { language } = useContext(Language);

    const { register } = useFormContext(); 

    let optionElements = [];
    let i = 1;
    for (const month of language.completesignup.monthList) {
        optionElements.push(
            <option key={i} value={i.toString().padStart(2, '0')}>{month}</option>
        );

        i++;
    }

    return (
        <select {...register("birthMonth", { required: true })}>
            {optionElements}
        </select>
    );
};

const DaySelect = () => {

    const { register } = useFormContext();

    let optionElements = [];
    for (let i = 1; i <= 31; i++) {
        let value = i.toString().padStart(2, '0');

        optionElements.push(
            <option key={i} value={value}>{value}</option>
        );
    }

    return (
        <select {...register("birthDay", { required: true })}>
            {optionElements}
        </select>
    );
};

const YearSelect = () => {

    const { register } = useFormContext();

    let optionElements = [];
    for (let i = 1920; i <= 2022; i++) {
        optionElements.push(
            <option key={i} value={i}>{i}</option>
        );
    }

    return (
        <select {...register("birthYear", { required: true })}>
            {optionElements}
        </select>
    );
};

const DateSelect = () => {
    const { language } = useContext(Language);

    return (
        <div className="date-select">
            <div>{language.completesignup.month}: <MonthSelect/></div>
            <div>{language.completesignup.day}: <DaySelect/></div>
            <div>{language.completesignup.year}: <YearSelect/></div>
        </div>
    )
};

export default DateSelect;
