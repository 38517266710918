import { test } from './test'

export const languages = [
  {
    lang: 'en',
    login: 'login',
    signup: 'sign up',
    header: {
      terms: 'Terms',
      about: 'About',
      history: 'History',
      contact: 'Contact',

    },
    english: 'English',
    spanish: 'Spanish',
    preferredLanguage: 'Preferred Language',

    home1: 'No more worrying about your voter registration status.',
    home2: 'If any changes occur to your voter registration status, you will be notified.',
    home3: 'Secure your vote',
    home4: 'Your voter registration information is very important. It can determine where you vote, how your vote is counted, and which candidates and issues are on your ballot.',
    home5: 'If you are voting by mail, then you need your voter registration information to be correct in order to receive and process a mail-in ballot.',
    home6: 'VoteFlare™ makes sure you always know what is happening with your voter registration and alert you about any changes.',
    about:
      [
        '<h1>VoteFlare™ notifies you if your voter information changes.</h1>',
        '<p>You register and you vote and it is usually a smooth process. But sometimes unexpected things can happen --such as, database errors, accidental purges, or inappropriate or spurious changes. Any of these can keep your vote from counting on election day. If you know there is a problem before election day, you can often fix it. That’s where VoteFlare™ helps you. </p>',
        '<p>VoteFlare™ regularly checks official information from state and local governments and then emails, texts or phones you if your voter information changes. VoteFlare™ may give you the time you need to make corrections before election day. It is like credit monitoring but for your voter information.</p>',
        '<h1>You can identify VoteFlare™ messages.</h1>',
        '<p>As the name implies, VoteFlare™ sends "flares" that warn you something changed. These flares take the form of SMS text messages, phone calls, or email messages (based on your selections). Our messages avoid impersonation because any messages from VoteFlare™ simply tells you that something changed and reminds you to visit voteflare.org for details. We never provide or ask for any personal information by email, text, or phone. </p>',
        '<h1>VoteFlare™ keeps your information safe.</h1>',
        '<p>VoteFlare™ never changes any of your voter information. It merely reads information and reports to you if changes occur.</p>',
        '<h1>VoteFlare™ is independent of parties and campaigns.</h1>',
        '<p>VoteFlare™ is not associated with any political party or campaign. It is a personal voter service. An organization may purchase coupons and give them to voters. But even in these cases, the communication still remains between VoteFlare™ and the voter.</p>',
        '<h2>VoteFlare™ is a project from the Data Privacy Lab, in the Public Interest Technology Lab, at Harvard University.</h2>'
      ],
    history:
    [
      '<h1>TERMS OF SERVICE FOR VOTEFLARE™ ON THE MYDATACAN PLATFORM</h1>',
      '<p>Welcome to VoteFlare™, a voter registration monitoring service offered by the Data Privacy Lab at Harvard University (“Harvard”) on the Harvard Data Privacy Lab’s MyDataCan platform.</p>',
      '<p>By accessing content on the voteflare.org website (the “Site,” which includes all pages within the voteflare.org web domain), subscribing to or using the VoteFlare™ app (the “App”) on MyDataCan, and/or accessing the VoteFlare™ Service, you accept and agree to be bound by the following Terms of Service:</p>',
    ],
    completesignup: {
      loading: "Loading...",
      info: "Your Info",
      username: "Username or Email",
      firstname: "First Name",
      lastname: "Last Name",
      state: "Choose a state",
      county: "County",
      zip: "ZIP Code",
      birthdate: "Date of Birth",
      month: "Month",
      monthList: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
      ],
      day: "Day",
      year: "Year",
      required: "This field is required",

      alertSettings: "Your Alert Settings",
      submit: "Submit",
      submitting: "Submitting"
    },
    alertPrefs: {
      email: 'Email',
      phoneSms: 'Phone (SMS)',
      phoneVoice: 'Phone (Voice)',
      sourceOfAlerts: "Source of alerts",
      alertsAboutVoteflare: "Alerts about changes to my voter information from VoteFlare™",
      enablePartnerAlerts: <>
        Yes, share my information with VoteFlare™ Partners<a href="/terms/">*</a> and have
        them notify me if any
        action is needed.
      </>,
      alertsAboutPartners: "Alerts about changes to my voter information from VoteFlare™ Partners",
      sms: "SMS",
      voice: "Voice",
    },
    portal: {
      loading: "Loading...",

      yourRegistration: "Your Registration",
      noteRegistration: "It may take approximately 2 to 10 minutes for your registration info to be updated.",
      yourBallot: "Your Ballot",
      noteBallot: "It may take approximately 2 to 10 minutes for your ballot info to be updated.",
      refreshView: "Refresh View",
      updateInformation: "Update Information",
      updating: "Updating...",
      noNewInformation: "No new voter registration information.",

      yourSettings: "Your Settings",
      informationRequired: "The following information is required to monitor your voter information:",

      firstname: "First Name",
      lastname: "Last Name",
      state: "Choose a state",
      county: "County",
      zip: "ZIP Code",
      birthdate: "Date of Birth",


      submitting: "Submitting...",
      saveChanges: "Save Changes",
      alertSettingsUpdated: "Alert settings updated.",
      majorChangesFound: "[WARNING: MAJOR CHANGES FOUND]",
      noMajorChanges: "[NO MAJOR CHANGES]",

      alertSettings: "Alert settings",
      field: "Field",
      value: "Value"
    },

  },
  {
    lang: 'es',
    login: 'iniciar la sesión',
    signup: 'Inscribirse',
    header: {
      terms: 'Términos',
      about: 'Acerca de VoteFlare',
      history: 'Historia',
      contact: 'Contacto'
    },
    english: 'Inglés',
    spanish: 'Español',
    preferredLanguage: 'Lengua de preferencia',
    home1: 'No se preocupe más por el estatus de su registro electoral.',
    home2: 'Si ocurre algún cambio en el estatus de su registro electoral, se le notificará a usted.',
    home3: 'Asegure su voto',
    home4: 'La información de su registro electoral es sumamente importante. Esto puede determinar dónde usted vota, cómo se contabiliza su voto, y cuáles candidatos y asuntos aparecen en su papeleta a la hora de votar.',
    home5: 'Si usted vota por correo, necesitará que su información de registro electoral esté correcta para poder recibir y procesar la papeleta que se le enviará por correo.',
    home6: 'VoteFlare™ se asegura que usted siempre sepa qué está pasando con su registro electoral y le notifica si ocurre algún cambio.',
    about:
    [
      '<h1>VoteFlare™ le notifica si ha habido algún cambio en su información de elector.</h1>',
      '<p>Usted se registra y vota, y por lo general es un proceso sencillo. Pero en algunas circunstancias suceden cosas imprevistas, tales como errores en la base de datos, purgas accidentales o cambios inapropiados o incorrectos. Cualquiera de estas situaciones puede causar que no se cuente su voto durante el día de las elecciones. Si usted sabe que hay algún problema antes del día de las elecciones, por lo general usted lo podrá arreglar. Es en ese momento cuando VoteFlare™ le ayuda.</p>',
      '<p>VoteFlare™ revisa regularmente la información oficial de los estados y los gobiernos locales y después se comunica por email, texto o por teléfono con usted si hay algún cambio en su información de elector. VoteFlare™ le puede dar el tiempo que usted necesita para hacer las correcciones necesarias antes del día de las elecciones. Es como un monitoreo de crédito, pero para su información electoral.</p>',
      '<h1>Usted podrá escoger los mensajes de VoteFlare™.</h1>',
      '<p>Tal como implica el nombre, VoteFlare™ (flare quiere decir en inglés una señal que se enciende) VoteFlare™ envía "señales" que le advierten que algo ha cambiado. Estas señales llegan a través de mensajes de texto, llamadas telefónicas o mensajes de email (según lo que usted haya escogido). Nuestros mensajes evitan que alguien se apropie de su identidad porque cualquier mensaje de VoteFlare™ sencillamente le deja saber que algo ha cambiado y le recuerda visitar la página voteflare.org para obtener los detalles. Nunca le proveemos o le preguntamos ninguna información personal a través de email, texto o teléfono. </p>',
      '<h1>VoteFlare™ mantiene su información segura.</h1>',
      '<p>VoteFlare™ no hace ningún cambio a su información de elector. Solamente lee la información y le emite un reporte para informarle que algún cambio ha ocurrido.</p>',
      '<h1>VoteFlare™ es independiente de partidos y campañas.</h1>',
      '<p>VoteFlare™ no está asociado con ningún partido político ni con ninguna campaña. Es un servicio personal para el elector. Una organización puede comprar cupones y dárselos a los electores para evitar que tengan que pagar la tarifa por el servicio. Pero aún en estos casos, la comunicación todavía se mantiene solamente entre VoteFlare™ y el elector. </p>',
    ],

    terms:
      [

      ],
    completesignup: {
      loading: "Cargando…",
      info: "Perfil",
      username: "Nombre de usuario / Email",
      firstname: "Nombre",
      lastname: "Apellido",
      state: "Escoja un estado",
      county: "Condado",
      zip: "Código postal",
      birthdate: "Fecha de nacimiento",
      month: "Mes",
      monthList: [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciembre'
      ],
      day: "Día",
      year: "Año",
      required: "Este campo es requerido.",

      alertSettings: "Escoja cómo prefiere recibir los mensajes de alerta",
      submit: "Entregar",
      submitting: "Entregando..."
    },
    alertPrefs: {
      email: 'Email',
      phoneSms: 'Teléfono (Mensaje SMS)',
      phoneVoice: 'Teléfono (Voz)',
      sourceOfAlerts: "Fuente de los mensajes de alerta",
      alertsAboutVoteflare: "Los mensajes de alerta sobre los cambios a mi información electoral de VoteFlare™",
      enablePartnerAlerts: <>
        Sí, compartan mi información con los Socios de VoteFlare™<a href="/terms/">*</a><br />y pídanles que me notifiquen si se requiere alguna acción de mi parte.
      </>,
      alertsAboutPartners: "Los mensajes de alerta sobre los cambios que surjan en mi información electoral por parte de los Socios de VoteFlare™.",
      sms: "SMS",
      voice: "Voz",
    },
    portal: {
      loading: "Cargando…",

      yourRegistration: "Su Registro",
      noteRegistration: "El proceso para actualizar su información de registro puede tomar de 2 a 10 minutos.",
      yourBallot: "Su papeleta para votar",
      noteBallot: "El proceso para actualizar la información de su papeleta electoral puede tomar de 2 a 10 minutos.",
      refreshView: "Actualizar la vista",
      updateInformation: "Actualizar la información",
      updating: "Actualizando...",
      noNewInformation: "No hay información nueva de inscripción de elector.",

      yourSettings: "Su configuración",
      informationRequired: "Se requiere la siguiente información para poder monitorear la información de su registro de elector:",

      firstname: "Nombre",
      lastname: "Apellido",
      state: "Escoja un estado",
      county: "Región",
      zip: "ZIP Code",
      birthdate: "Fecha de nacimiento",

      submitting: "Entregando...",
      saveChanges: "Guardar los cambios",
      alertSettingsUpdated: "Cambió de la configuración de alerta.",
      majorChangesFound: "[ADVERTENCIA: SE ENCONTRARON CAMBIOS IMPORTANTES]",
      noMajorChanges: "[SIN CAMBIOS IMPORTANTES]",

      alertSettings: "Configuración de alertas",
      field: "Información",
      value: "Data"
    }
  },
]
