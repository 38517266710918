import React, { useEffect, useState, useContext } from "react";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { Language } from "../../context/language";

import AlertPrefs from '../../forms/alertPrefs';

import axios from '../../tools/axiosWrapper';



const SettingsPanel = () =>  {
    const { language } = useContext(Language);

    const methods = useForm();
    const { register, handleSubmit, watch, setValue, formState: { errors } } = methods;

    const [ isLoading, setIsLoading ] = useState(true);
    const [ isSubmitting, setIsSubmitting ] = useState(false);
    const [ voterInfo, setVoterInfo ] = useState({});

    const onSubmit = (data) => {
        setIsSubmitting(true);

        axios.post('/contact_prefs', data)
        .then(res => {
            alert(language.portal.alertSettingsUpdated);
        })
        .catch(() => {
            // alert("Error");
        })
        .finally(() => {
            setIsSubmitting(false);
        });
    };

    useEffect(() => {
        setIsLoading(true);
        axios.get('/voter/info')
            .then(res => {
                const { data } = res;

                setVoterInfo(data);

                setValue('contactLanguage', data.input.contact_language);
                setValue('contactEmail', data.input.contact_email);
                setValue('contactSms', data.input.contact_sms);
                setValue('contactVoice', data.input.contact_voice);

                setValue('alertPrefsMain', data.input.main);
                setValue('partnerAlertsEnabled', data.input.partner.length > 0);
                setValue('alertPrefsPartner', data.input.partner);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    if (isLoading) {
        return <p>Loading...</p>
    }

    return (
        <div>
            <h2>{language.portal.yourSettings}</h2>
            <p class="is-italic">
                {language.portal.informationRequired}
            </p>
            <p>{language.portal.firstname}: {voterInfo.firstname}</p>
            <p>{language.portal.lastname}: {voterInfo.lastname}</p>
            <p>{language.portal.county}: {voterInfo.county}</p>
            <p>{language.portal.zip}: {voterInfo.zip}</p>
            <p>{language.portal.birthdate}: {voterInfo.birthdate}</p>

            <FormProvider {...methods}>
                <form className='mainform' onSubmit={handleSubmit(onSubmit)}>
                    <h3>{language.portal.alertSettings}</h3>
                    <AlertPrefs />
                    <button type="submit" disabled={isSubmitting}>
                        {isSubmitting ? language.portal.submitting : language.portal.saveChanges}
                    </button>
                </form>
            </FormProvider>
        </div>
    );
};


export default SettingsPanel;
