import React, { useContext } from "react";
import StatusPanel from "./panels/status";
import SettingsPanel from "./panels/settings";
import './portal.css';

import { Language } from '../context/language'

const Portal = () => {
    const { language } = useContext(Language);

    return (
        <div className='portal-main'>
            <div className='portal-status'>
                <StatusPanel
                    title={language.portal.yourRegistration}
                    note={language.portal.noteRegistration}
                    route="/voter/registration"
                />
                <br />
                <br />
                <br />
                <StatusPanel 
                    title={language.portal.yourBallot}
                    note={language.portal.noteBallot}
                    route="/voter/ballot"
                />
            </div>
            <br />
            <br />
            <br />
            <div>
                <SettingsPanel />
            </div>
        </div>
    );
};


export default Portal;
