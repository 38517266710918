import React, { useEffect, useMemo, useState, useContext } from "react";
import axios from '../../tools/axiosWrapper';
import {timeParse, timeFormat, utcParse} from "d3-time-format";

import { Language } from '../../context/language';

function isEmpty(obj) {
    for (var prop in obj) {
        if (obj.hasOwnProperty(prop))
            return false;
    }

    return true;
}

function fieldHasAlert(field, alerts) {
    if (!alerts) {
        return false;
    }

    for (let key of Object.keys(alerts)) {
        if (key.includes(field)) {
            return true;
        }
    }

    return false;
}

function getAlertInField(field, alerts) {
    if (!fieldHasAlert(field)) {
        return null;
    }

    const alert = alerts[`alert_change_${field}_new`] || null;
    return alert;
}

function fieldIsChanged(field, changes) {
    for (let key of Object.keys(changes)) {
        if (key.includes(field)) {
            return true;
        }
    }

    return false;
}

function getChangedField(field, changes) {
    if (!fieldIsChanged(field, changes)) {
        return null;
    }

    const oldValue = changes[`change_${field}_old`] || null;
    const newValue = changes[`change_${field}_new`] || null;

    if (!oldValue && !newValue) {
        return null;
    }

    return {
        oldValue,
        newValue
    };
}



const StatusPanel = ({ title, note, route }) => {
    const { language } = useContext(Language);

    const [ isLoadingInfo, setIsLoadingInfo ] = useState(false);
    const [ isLookingUp, setIsLookingUp ] = useState(false);
    const [ lookupFinished, setLookupFinished ] = useState(false);

    const [ info, setInfo ] = useState({});
    const [ changes, setChanges ] = useState({});
    const [ alerts, setAlerts ] = useState({});

    const hasInfo = useMemo(() => !isEmpty(info));
    const hasChanges = useMemo(() => alerts.status === "Warning");

    useEffect(() => {
        fetchData();
    }, []);

    function clearInfo() {
        setInfo({});
        setChanges({});
        setAlerts({});
    }

    function fetchData() {
        setIsLoadingInfo(true);

        axios.get(route)
        .then(res => {
            const { data } = res;

            if (isEmpty(data)) {
                clearInfo();
            } else {
                setInfo(data.info || {});
                setChanges(data.changes);
                setAlerts(data.alerts);
            }
        })
        .catch(error => {
            console.log(error);
        })
        .finally(() => {
            setIsLoadingInfo(false);
        })
    }

    function requestLookup() {
        setIsLookingUp(true);

        return axios.post('/voter/start_lookup')
        .catch(error => {
            console.log(error)
        })
        .finally(() => {
            setIsLookingUp(false);
        });
    }

    return (
        <div>
            <h2>{title} <br/><StatusLabel hasInfo={hasInfo} hasChanges={hasChanges} /></h2>
            {
                isLoadingInfo
                ? <p>{language.portal.loading}</p>
                : <InfoTable info={info} alerts={alerts} changes={changes} />
            }
            <FetchButton isLoadingInfo={isLoadingInfo} fetchData={fetchData} />
            <LookupButton isLookingUp={isLookingUp} requestLookup={requestLookup} setLookupFinished={setLookupFinished} />
            {
                lookupFinished &&
                <p>{note}</p>
            }
        </div>
    );
};

const StatusLabel = ({ hasInfo, hasChanges }) => {
    const { language } = useContext(Language);

    if (hasInfo) {
        if (hasChanges) {
            return <span style={{ color: "#ff1818" }}>{language.portal.majorChangesFound}</span>;
        }

        return <span style={{ color: "#00f100" }}>{language.portal.noMajorChanges}</span>;
    }

    return null;
};

const InfoTable = ({ info, alerts, changes }) => {
    delete info.timestamp




    if (!isEmpty(info)){
      const parse = timeParse("%Y-%m-%d %H:%M:%S UTC")
      const format = timeFormat("%B %d, %Y")

      let date = info.datetime
      date = parse(date)
      date = format(date)
      info.when=String(date)
      // delete info.datetime

    }



    const { language } = useContext(Language);

    const hasInfo = useMemo(() => !isEmpty(info));

    if (!hasInfo) {
        return <p>{language.portal.noNewInformation}</p>
    }

    const rowElements = [];

    const InfoTableRow = ({ index, property }) => {



        const value = info[property];

        const hasAlert = useMemo(() => fieldHasAlert(property, alerts), [property]);
        const _alert = useMemo(() => getAlertInField(property, alerts), [property]);

        const hasChange = useMemo(() => !getChangedField(property, changes), [property]);
        const changedField = useMemo(() => getChangedField(property, changes), [property]);

        return (
            <tr key={index}>
                <td style={{textTransform: 'capitalize'}}>{property.replace('_', ' ')}</td>
                <td>
                    {
                        hasAlert &&
                        <span style={{ color: "#e60000" }}>
                            <b dangerouslySetInnerHTML={{ __html: _alert || '<p></p>'}}></b>
                            <br />
                        </span>
                    }
                    {
                        hasChange
                        ? <span dangerouslySetInnerHTML={{ __html: value || '<p></p>'}}></span>
                        : <div>
                            <span style={{ color: "#e60000" }}>
                                {changedField.newValue}
                            </span>
                            <br />
                            <span>{changedField.oldValue}</span>
                        </div>
                    }
                </td>
            </tr>
        );
    };

    let index = 0;
    for (const key in info) {

        rowElements.push(<InfoTableRow index={index} property={key} />)
        index += 1;
    }



    return (
        <div className='portal-table'>
            <table>
                <thead>
                    <tr>
                        <th>{language.portal.field}</th>
                        <th>{language.portal.value}</th>
                    </tr>
                </thead>
                <tbody>
                    {rowElements}
                </tbody>
            </table>
        </div>
    );
};


const FetchButton = ({ isLoadingInfo, fetchData }) => {
    const { language } = useContext(Language);

    const onClick = () => {
        fetchData();
    };

    return (
        <button className='portal-button' onClick={onClick} disabled={isLoadingInfo}>
            {isLoadingInfo ? language.portal.loading : language.portal.refreshView}
        </button>
    );
};

const LookupButton = ({ isLookingUp, setLookupFinished, requestLookup }) => {
    const { language } = useContext(Language);

    const onClick = () => {
        setLookupFinished(false);
        requestLookup()
        .then(() => {
          setLookupFinished(true);
        });
    };

    return (
        <button className='portal-button' onClick={onClick} disabled={isLookingUp}>
            {isLookingUp ? language.portal.updating : language.portal.updateInformation}
        </button>
    );
}



export default StatusPanel;
