import { useState, useEffect, useContext, useMemo } from 'react';
import { render } from "react-dom";
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import Home from './home/home'
import Header  from './header/header'
import Other  from './other/other'
import History from './other/history'
import Terms from './other/terms'
import CompleteSignUp  from './forms/completesignup'
import Portal from './portal/portal';
import { Language } from './context/language'
import { languages } from './context/languages'


function App() {

  const [language, set_language] = useState(languages[0]);

  const value = useMemo(() => ({ language, set_language }), [language, set_language]);

  return (
    <>
      <BrowserRouter>
        <Language.Provider value={value}>
          <Routes>
            <Route path='/' element={<Header />}>
              <Route index element={<Home />} />
              <Route path='/about' element={<Other page={language.about} />} />
              <Route path='/terms' element={<Terms />} />
              <Route path='/history' element={<History />} />
              <Route path='/complete_sign_up' element={<CompleteSignUp /> } />
              <Route path='/portal' element={<Portal /> } />
            </Route>
          </Routes>
        </Language.Provider>
      </BrowserRouter>
    </>
  );
}

export default App;
